
.react-multi-carousel-dot--active button {
    background: #25387c;
}

.react-multi-carousel-dot button
{
	border-color: #25387c;
}
.fc-daygrid-event {
    white-space: pre-wrap !important;
    overflow: auto;
}

/*course outside  issue*/
*, *::before, *::after {
    box-sizing: inherit;
}
/* excel button */

button#test-table-xls-button {
    font-size: 14px;
    padding: 16px 16px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    color: white;
    background-color: #25387c;
    margin-bottom: 16px;
    box-shadow: rgb(37 56 124 / 24%) 0px 8px 16px 0px;
    background-color: #25387c;
    cursor:pointer;
}

/* calender css */

.fc .fc-toolbar-title {
    font-size: 20px !important;
    margin: 0;
}

.fc .fc-button-primary:disabled {
    background-color: #25387c !important;
}
.fc .fc-button-primary {
    background-color: #25387c !important;
}
.fc .fc-button-primary:focus {
    border-color: #f9bb02 !important;
    box-shadow: 0 0 0 0rem rgb(76 91 106 / 50%) !important;
    background-color: #f9bb02 !important;
}

.slidesHolder{
	height:300px !important
}
.slideContainer{
	min-height:300px !important
}
