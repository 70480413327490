
.my-anchor-css-class:link {
  text-decoration: unset;
      padding: 4px 5px;
    font-size: 0.8125rem;
    color: #25387c;
}
.my-anchor-css-class:hover {
	background-color: rgba(37, 56, 124, 0.04);
}

