
@media screen and (min-width: 100px) and (max-width: 160px) {
 .webframe{height:calc(100vh - 50px)}
}

@media screen and (min-width: 161px) and (max-width: 250px) {
 .webframe{height:calc(100vh - 50px)}

}
@media screen and (min-width: 251px) and (max-width: 320px) {
 .webframe{height:calc(100vh - 10px)}
}

@media screen and (min-width: 321px) and (max-width: 370px) {
 .webframe{height:calc(100vh - 146px)}

}


@media screen and (min-width: 371px) and (max-width: 450px) {
  .webframe{height:calc(100vh - 187px)}
}

@media screen and (min-width: 451px)  and (max-width: 700px)  {
  .webframe{height:calc(100vh - 187px)}

}

@media screen and (min-width: 701px)  and (max-width: 900px)  {
  .webframe{height:calc(100vh - 187px)}

}

@media screen and (min-width: 901px)  {
  .webframe{height:calc(100vh - 144px)}

}
