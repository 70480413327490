.blog span h2,h3{
	
	color: #25387c;
    font-size: 16px;
    font-family: IBM Plex Sans,-apple-system,BlinkMacSystemFont,sans-serif;
    font-weight: 600;
    line-height: 1.75;
	
}
.blog img{
    border-radius: 12px;
	    width: 100%;

}
.blog span h2{
    font-size: 18px;
}
.blog em,strong,h4{
	color: #1A2027;
}
.blog span a{
   color: #25387c;
}

.blog span ul{
    margin:16px 16px 16px 24px;
}
.blog span p strong{
 /*   color: black; */
}
em{  font-weight: 600}
